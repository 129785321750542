/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import {
    shape, string,
} from 'prop-types';

import NupV4Panel from './NupV4Panel';
import NupV4Video from './NupV4Video';
import BlockHeading from '../BlockHeading';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        gap: '16px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        [theme.breakpoints.down(740)]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    mainContainer: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '1400px',
        [theme.breakpoints.down(1401)]: {
            padding: '0px 10px',
        },
    },
    mainHeader: {
        fontSize: '2.875rem',
        marginBottom: '18px',
        marginTop: '0px',
        lineHeight: '58px',
        fontFamily: 'Crimson, Garamond, Times, serif',
        [theme.breakpoints.down(769)]: {
            fontSize: '2rem',
            lineHeight: '40px',
        },
    },
    multiProduct: {
        gridTemplateColumns: 'repeat(4, 1fr)',
        [theme.breakpoints.down(740)]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
        },
    },
    threeProduct: {
        gridTemplateColumns: 'repeat(3, 2fr)',
        [theme.breakpoints.down(740)]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '8px',
        },
    },
}));

const NupV4 = ({ data }) => {
    if (!data || Object.keys(data.reference?.[0]).length === 0) return null;
    const itemsList = data?.reference?.[0].entries;
    const styling = data?.reference?.[0].styling;
    const title = data?.reference?.[0].heading;
    const classes = useStyles({ styling });
    const seed = useUIDSeed();
    const productLength = itemsList?.length;
    const loadCssProductBase = () => {
        switch (productLength) {
            case 2:
                return '';
            case 3:
                return classes.threeProduct;
            default:
                return classes.multiProduct;
        }
    };
    return (
        <div container className={`${classes.mainContainer} nup-v4-container`}>
            {title && <BlockHeading heading={title} />}
            <div className={`${classes.gridContainer} ${loadCssProductBase()} nup-v4-grid-container`}>
                {itemsList?.map((Data) => (
                    Data.video?.mp4?.content_type.includes('video') ? (
                        <NupV4Video key={seed(Data)} panelItem={Data} />
                    ) : (
                        <NupV4Panel key={seed(Data)} panelItem={Data} styling={styling} productLength={productLength} />
                    )
                ))}
            </div>
        </div>
    );
};

NupV4.propTypes = {
    data: shape({
        title: string,
    }),
};
NupV4.defaultProps = {
    data: {},
};

export default (NupV4);
