/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    number,
    shape,
    string,
    oneOfType,
} from 'prop-types';
import { Typography, useMediaQuery } from '@material-ui/core';
import Caret from '@material-ui/icons/ArrowForwardIos';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import parseFontFamilyExtension from '../../../helpers/contentstack/parseFontFamilyExtension';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        maxHeight: '330px',
        height: '100%',
    },
    bottomContent: {
        color: theme.palette.cms?.black || '#2F2F2F',
        padding: '0 32px 0px',
        '@media screen and (max-width: 1023px)': {
            padding: '0 22px 0px',
        },
    },
    heading: ({ styling }) => ({
        fontSize: '2rem',
        lineHeight: '40px',
        fontFamily: `${parseFontFamilyExtension(styling.title_font) || 'Area Variable, LatoRegular, Arial, Verdana, sans-serif'}`,
        fontWeight: 500,
        paddingTop: '16px',
        '@media screen and (max-width: 1023px)': {
            fontSize: '1.5rem',
            lineHeight: '32px',
        },
    }),
    subHeading: ({ styling }) => ({
        fontSize: '1rem',
        lineHeight: '24px',
        fontFamily: `${parseFontFamilyExtension(styling.sub_title_font) || 'Area Variable, LatoRegular, Arial, Verdana, sans-serif'}`,
        fontWeight: '400',
        paddingTop: '13px',
    }),
    ctaBtn: ({ styling }) => ({
        padding: '11px 40px 12px 37px',
        display: 'flex',
        fontSize: '1.25rem',
        border: `1.5px solid ${(styling.has_custom_colors && styling?.cta_border_color.color) || theme.palette.ctaBorderClrNup4 || '#2F2F2F'}`,
        width: 'auto',
        textAlign: 'center',
        fontFamily: `${parseFontFamilyExtension(styling.sub_title_font) || 'Area Variable, LatoRegular, Arial, Verdana, sans-serif'}`,
        lineHeight: '22px',
        margin: '24px 0px 32px 0px',
        alignItems: 'center',
        background: (styling.has_custom_colors && styling?.cta_background_color.color) || theme.palette.ctaBgColorNup4 || '#ffffff',
        color: (styling.has_custom_colors && styling?.cta_font_color.color) || theme.palette.ctaColorNup4 || '#2f2f2f',
        maxWidth: 'max-content',
        '@media screen and (max-width: 1366px)': {
            fontSize: '1rem',
        },
        '@media screen and (max-width: 1023px)': {
            lineHeight: '20px',
            padding: '8px 22px 9px 22px',
            margin: '20px 0px 32px 0px',
        },
    }),
    linkPanel: {
        textDecoration: 'none',
        lineHeight: '0px',
        border: `1px solid ${(theme.palette.text?.mainSBSContainer || '#AFAFAF')}`,
    },
    arrowIcon: {
        width: '0.7em',
        height: '0.7em',
        fontSize: '1.25rem',
        margin: '0px 0px 0px 5px',
    },
    threeProductLayout: () => ({
        '& $bottomContent': {
            padding: '0 26px 28px',
            '@media screen and (max-width: 1023px) and (min-width:767px)': {
                padding: '0 11px 28px',
            },
        },
        '& $heading': {
            fontSize: '1.7rem',
            '@media screen and (max-width: 1023px)': {
                fontSize: '1.25rem',
                lineHeight: '30px',
            },
            '@media screen and (max-width: 767px) and (min-width: 740px)': {
                minHeight: '86px',

            },
        },
        '& $subHeading': {
            paddingTop: '5px',
            '@media screen and (max-width: 1023px) and (min-width: 740px)': {
                display: 'none',
            },
        },
    }),
    multiProductLayout: {
        '& $bottomContent': {
            padding: '0px 19px 28px',
            '@media screen and (max-width: 1023px)': {
                padding: '0px 10px 28px',
            },
            '@media screen and (max-width: 420px)': {
                paddingBottom: '0px',
            },
        },
        '& $heading': {
            fontSize: '1.25rem',
            lineHeight: '28px',
            paddingTop: '20px',
            '@media screen and (max-width: 1023px)': {
                fontSize: '1.125rem',
                lineHeight: '26px',
            },
            '@media screen and (max-width: 840px) and (min-width: 768px)': {
                minHeight: '78px',
            },
        },
        '& $subHeading': {
            display: 'none',
        },
        '& $arrowIcon': {
            margin: '2px 0px 0px 5px',
        },
    },
    threeProductCTA_standard: {
        fontSize: '1rem',
        lineHeight: 'normal',
        margin: '13px 0px 30px 0px',
        border: 'none',
        padding: 0,
        display: 'flex',
        textAlign: 'left',
        width: '100%',
        '@media screen and (max-width: 767px) and (min-width: 740px)': {
            minHeight: '40px',
            fontSize: '0.875rem',
        },
    },
    multiProductLayoutCTA_standard: {
        fontSize: '1rem',
        lineHeight: '20px',
        padding: '15px 0px 30px 0px',
        border: 'none',
        margin: '0px',
        textAlign: 'left',
        width: '100%',
        display: 'flex',
        '@media screen and (max-width: 1023px)': {
            paddingTop: '5px',
        },
        '@media screen and (max-width: 599px)': {
            padding: '14px 0px 23px 0px',
            letterSpacing: '0.02em',
            '& $arrowIcon': {
                fontSize: '1.2rem',
                margin: '3px 0px 0px 5px',
            },
        },
    },
}));

const NupV4Panel = ({
    panelItem, styling, productLength,
}) => {
    const classes = useStyles({
        styling,
    });
    const loadCssProductBase = () => {
        switch (productLength) {
            case 2:
                return '';
            case 3:
                return classes.threeProductLayout;
            default:
                return classes.multiProductLayout;
        }
    };

    const paneltitle = panelItem?.linking?.link;
    const isMobile = useMediaQuery('(max-width: 600px)');
    const imagePath = isMobile ? panelItem?.mobile_image : panelItem?.image;
    const dimensions = isMobile ? panelItem?.mobile_image?.dimension : panelItem?.image?.dimension;
    const ctaStyle = styling.cta_style;
    const ctaPresentation = () => {
        const isStandard = (ctaStyle === 'standard' || ctaStyle === null || ctaStyle === undefined);
        switch (productLength) {
            case 2:
                return classes.ctaBtn;
            case 3:
                if (isStandard) return classes.threeProductCTA_standard;
                return classes.ctaBtn;
            default:
                if (isStandard) return classes.multiProductLayoutCTA_standard;
                return classes.ctaBtn;
        }
    };
    return (
        <LinkOrATag
            className={`${classes.linkPanel} ${loadCssProductBase()}`}
            href={paneltitle?.href}
            isTrackEventEnabled
            trackingEventAction={panelItem?.linking?.tracking_event_action}
            trackingEventCategory={panelItem?.linking?.tracking_event_category}
            trackingEventLabel={panelItem?.linking?.tracking_event_label}
        >
            <div className={classes.mainContainer}>
                <ResponsiveImage
                    className={classes.image}
                    path={imagePath?.url}
                    alt={imagePath?.description || 'Background Image'}
                    dimensions={dimensions}
                />
            </div>
            <div className={classes.bottomContent}>
                {panelItem?.title && (<Typography className={`${classes.heading} nup-v4-panel-item`}> {replaceSpecialChar(panelItem.title, true)} </Typography>) }
                {panelItem?.sub_title && (<Typography className={`${classes.subHeading} nup-v4-panel-item`}> {panelItem.sub_title} </Typography>) }
                {paneltitle?.title && (<Typography className={`${ctaPresentation()} nup-v4-panel-item`}> {paneltitle.title}{<Caret className={classes.arrowIcon} />}</Typography>)}
            </div>
        </LinkOrATag>
    );
};

NupV4Panel.propTypes = {
    panelItem: shape({
        title: string,
        image: shape({
            url: string,
            content_type: string,
        }),
    }),
    styling: shape({
        presentation_style: string,
        title_font_color: shape({
            color: string,
        }),
    }),
    productLength: oneOfType([number, string]),
};
NupV4Panel.defaultProps = {
    panelItem: {},
    styling: {},
    productLength: '',
};

export default NupV4Panel;
